import romanize from "romanize";
import * as _ from "lodash";
import * as NProgress from "nprogress";

import { IMAGE_URL } from "./constants";

function prettyDate(manifest, year, issue) {
  var issue = manifest.get(year).get(issue);
  if (issue.date == null) {
    return issue.year + "/" + issue.issue;
  } else {
    return (
      issue.date[0] +
      " " +
      romanize(issue.date[1]) +
      "\n" +
      issue.date[2] +
      " r."
    );
  }
}

type Document = {
  year: number;
  issue: number;
  page: number;
};

const SEARCH_ENDPOINT = "https://szukaj.merkuriusze.pl/2013-01-01/search";
const RESULT_COUNT = 2000;
const TYPING_DELAY = 250;
const MIN_TERM_LENGTH = 5;

export function initSearchSuggestions(
  manifest,
  dlibraId,
  titleSlug,
  inputElement,
  suggestionsElement
) {
  function showSuggestions() {
    let inputString = inputElement.value;
    if (inputString.length < MIN_TERM_LENGTH) {
      return;
    }

    NProgress.start();

    var terms = inputString
      .split(/(\s+)/)
      .map((term) => term.trim().toLowerCase())
      .filter((term) => term.length > 0);

    if (terms.length == 0) {
      return;
    }

    let matches = terms[0].match(/^"(.*)"$/);

    var andOperands: string[] = [];
    if (terms.length == 1 && matches != null) {
      var term = matches[1];
      andOperands.push(`phrase field='text' '${term}'`);
    } else {
      term = terms.join(" ");
      andOperands.push(`near field='text' distance=2 '${term}'`);
    }

    if (dlibraId == 5871) {
      andOperands.push("range field='page' {,12]");
    }
    andOperands.push(`term field=dlibra_id ${dlibraId}`);

    let searchQuery = andOperands.reduce((lhs, rhs) => `and (${lhs}) (${rhs})`);
    const searchParams = new URLSearchParams();
    searchParams.set("q", `(${searchQuery})`);
    searchParams.set("q.parser", "structured");

    searchParams.set("sort", "year asc,issue asc");
    searchParams.set("size", RESULT_COUNT.toString());
    fetch(SEARCH_ENDPOINT + "?" + searchParams.toString())
      .then((response) => response.json())
      .then((json) => {
        const results = _(json["hits"]["hit"])
          .map((row) => {
            return {
              year: +row.fields.year,
              issue: +row.fields.issue,
              page: +row.fields.page,
            } as Document;
          })
          .groupBy((document) => document.year + "-" + document.issue)
          .values()
          .map(0)
          .sortBy(["year", "issue"])
          .value();

        NProgress.done();

        suggestionsElement.classList.remove("invisible");
        if (results.length == 0) {
          suggestionsElement.classList.add("empty");
          suggestionsElement.innerText = "Brak wyników.";
        } else {
          suggestionsElement.classList.remove("empty");
          suggestionsElement.innerHTML = "";
          for (let result of results) {
            var suggestionElement = document.createElement(
              "a"
            ) as HTMLAnchorElement;
            let imageUrl = [
              IMAGE_URL,
              "issueThumbnail",
              dlibraId,
              result.year,
              result.issue,
            ].join("/");
            suggestionElement.style.background = "url(" + imageUrl + ")";
            suggestionElement.href = `/${titleSlug}/${result.year}/${
              result.issue
            }#${result.page + 1}`;
            var p = document.createElement("p") as HTMLParagraphElement;
            p.innerText = prettyDate(manifest, result.year, result.issue);
            suggestionElement.appendChild(p);
            suggestionsElement.appendChild(suggestionElement);
          }
        }
      });
  }

  let timer: number | null = null;
  inputElement.addEventListener("keydown", () => {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(showSuggestions, TYPING_DELAY);
  });
  suggestionsElement.addEventListener("click", (event) => {
    event.stopPropagation();
  });
  document.body.addEventListener("click", (event) => {
    suggestionsElement.classList.add("invisible");
  });
}
