import { IMAGE_URL, RESIZE_DELAY } from "./constants";

import * as Backbone from "backbone";
import * as _ from "lodash";
import * as promiseToolbox from "promise-toolbox";
import { Issue } from "./manifest";
import panzoom, { PanZoom } from "panzoom";

class Canvas {
  element: HTMLImageElement;
  url: URL;
  dirty: boolean;

  constructor(url: string) {
    this.element = document.createElement("img");
    this.element.loading = "lazy";
    this.element.classList.add("preview");
    this.url = new URL(url);
    this.dirty = true;
  }

  markAsDirty(
    sourceImageDimensions: [number, number],
    imageDimensions: [number, number]
  ) {
    this.dirty = true;
    this.element.width = sourceImageDimensions[0];
    this.element.height = sourceImageDimensions[1];
    this.element.style.width = imageDimensions[0] + "px";
    this.element.style.height = imageDimensions[1] + "px";
  }

  async updateImage(sourceWidth: number) {
    let targetWidth = sourceWidth.toString();
    this.dirty = false;
    if (this.url.searchParams.get("width") != targetWidth) {
      this.url.searchParams.set("width", targetWidth);
      let promise = promiseToolbox.fromEvents(
        this.element,
        ["load"],
        ["error"]
      );
      this.element.src = this.url.toString();
      await promise;
    }
  }
}

export default class Newspaper implements Backbone.Events {
  on: Backbone.Events_On<Newspaper>;
  off: Backbone.Events_Off<Newspaper>;
  trigger: Backbone.Events_Trigger<Newspaper>;
  bind: Backbone.Events_On<Newspaper>;
  unbind: Backbone.Events_Off<Newspaper>;
  once: Backbone.Events_On<Newspaper>;
  listenTo: Backbone.Events_Listen<Newspaper>;
  listenToOnce: Backbone.Events_Listen<Newspaper>;
  stopListening: Backbone.Events_Stop<Newspaper>;

  private element: HTMLDivElement;
  private slug: string;
  private issue: Issue;
  private loadInProgress: boolean = false;
  private activePageNumber: number = 0;
  targetPageNumber: number = 0;
  private leftPage: HTMLDivElement;
  private rightPage: HTMLDivElement;
  private canvases: Canvas[] | null = null;
  private panzoom: PanZoom;
  private panningTimeout: number | null = null;
  private panning: boolean = false;
  private wheelZoomEnabled: boolean = true;

  constructor(element: HTMLDivElement, slug: string, issue: Issue) {
    this.element = element;
    this.leftPage = element.getElementsByClassName(
      "newspaper__page--0"
    )[0] as HTMLDivElement;
    this.rightPage = element.getElementsByClassName(
      "newspaper__page--1"
    )[0] as HTMLDivElement;
    this.slug = slug;
    this.issue = issue;
  }

  enableWheelZoom(enabled: boolean) {
    this.wheelZoomEnabled = enabled;
  }

  shiftCanvases(offset: number) {
    console.assert(this.canvases != null);
    console.assert(this.activePageNumber % 2 == 0);
    console.assert(this.activePageNumber >= 0);
    console.assert(this.activePageNumber <= this.canvases!.length);
    console.assert(offset % 2 == 0);
    console.assert(this.activePageNumber + offset >= 0);
    console.assert(this.activePageNumber + offset <= this.canvases!.length);
    this.activePageNumber += offset;

    for (let pageNumber = 0; pageNumber <= 3; pageNumber++) {
      const page = document.getElementById(
        "page" + pageNumber
      ) as HTMLDivElement;
      if (page.firstElementChild != null) {
        page.removeChild(page.firstElementChild);
      }
      const canvasIdx = this.activePageNumber + pageNumber - 1;
      if (canvasIdx >= 0 && canvasIdx < this.canvases!.length) {
        page.appendChild(this.canvases![canvasIdx].element);
      }
    }
  }

  async moveToTargetPage() {
    const MIN_DURATION: number = 0.2;
    const MAX_DURATION: number = 0.9;

    let canvases: Canvas[] = this.canvases!;
    console.assert(this.targetPageNumber >= 0);
    console.assert(this.targetPageNumber <= canvases.length);
    console.assert(this.targetPageNumber % 2 == 0);
    console.assert(this.activePageNumber >= 0);
    console.assert(this.activePageNumber <= canvases.length);
    console.assert(this.activePageNumber % 2 == 0);

    while (this.activePageNumber != this.targetPageNumber) {
      const delta = Math.abs(this.activePageNumber - this.targetPageNumber) / 2;
      const factor = Math.pow(MAX_DURATION - MIN_DURATION, delta);
      const duration = MIN_DURATION + factor;
      document.documentElement.style.setProperty(
        "--transition-duration",
        duration.toFixed(1) + "s"
      );

      if (this.activePageNumber > this.targetPageNumber) {
        await this.turnLeft();
      } else {
        await this.turnRight();
      }
    }

    await this.updateVisiblePages();
  }

  async updateImage(index: number) {
    let canvases: Canvas[] = this.canvases!;
    await canvases[index]?.updateImage(this.sourceImageDimensions![0]);
  }

  async updateVisiblePages() {
    await Promise.all([
      this.updateImage(this.activePageNumber - 1),
      this.updateImage(this.activePageNumber),
      this.updateImage(this.activePageNumber + 1),
      this.updateImage(this.activePageNumber + 2),
    ]);
  }

  async turnLeft() {
    if (this.activePageNumber > 0) {
      this.rightPage.classList.remove("transition");
      this.rightPage.classList.add("rotated");
      this.rightPage.offsetHeight;
      this.shiftCanvases(-2);
      var transition = promiseToolbox.fromEvent(
        this.rightPage,
        "transitionend"
      );
      this.rightPage.classList.add("transition");
      this.rightPage.classList.remove("rotated");
      await transition;
    }
  }

  async turnRight() {
    if (this.activePageNumber < this.canvases!.length) {
      var transition = promiseToolbox.fromEvent(
        this.rightPage,
        "transitionend"
      );
      this.rightPage.classList.add("transition");
      this.rightPage.classList.add("rotated");
      await transition;
      this.shiftCanvases(2);
      this.rightPage.classList.remove("transition");
      this.rightPage.classList.remove("rotated");
      this.rightPage.offsetHeight;
    }
  }

  pageDimensionsForWindow(width: number, height: number): [number, number] {
    let renderScale = Math.min(
      width / this.issue.width / 2.0,
      height / this.issue.height
    );
    let pageWidth = renderScale * this.issue.width;
    pageWidth -= pageWidth % 64;
    pageWidth = Math.min(2048, Math.max(192, pageWidth));
    renderScale = pageWidth / this.issue.width;
    return [
      Math.round(renderScale * this.issue.width),
      Math.round(renderScale * this.issue.height),
    ];
  }

  private resizeTimeout: number | null = null;

  triggerResize(delay: number) {
    const zoomScale = this.panzoom?.getTransform().scale || 1;
    let sourceImageDimensions = this.pageDimensionsForWindow(
      window.innerWidth * zoomScale,
      window.innerHeight * zoomScale
    );
    if (_.isEqual(sourceImageDimensions, this.sourceImageDimensions)) {
      return;
    }

    if (this.resizeTimeout != null) {
      clearTimeout(this.resizeTimeout);
    }

    this.sourceImageDimensions = sourceImageDimensions;
    let imageDimensions: [number, number] = [
      sourceImageDimensions[0] / zoomScale,
      sourceImageDimensions[1] / zoomScale,
    ];
    this.imageDimensions = imageDimensions;
    this.element.style.width = imageDimensions[0] * 2 + "px";
    this.element.style.height = imageDimensions[1] + "px";

    if (this.canvases != null) {
      this.trigger("load:start");
      this.canvases.forEach((canvas) => {
        canvas.markAsDirty(sourceImageDimensions, imageDimensions);
      });
      this.resizeTimeout = setTimeout(() => {
        this.resizeTimeout = null;
        console.log("Zmieniam rozmiar renderowanych skanów…");
        this.updateVisiblePages().then(() => {
          this.trigger("load:complete");
        });
      }, delay);
    }
  }

  private sourceImageDimensions: [number, number] | null = null;
  private imageDimensions: [number, number] | null = null;

  private imageUrl(pageNumber: number): string {
    return [
      IMAGE_URL,
      "page",
      this.slug,
      this.issue.year,
      this.issue.issue,
      pageNumber,
    ].join("/");
  }

  isClickable(): boolean {
    return !this.loadInProgress && !this.panning;
  }

  updateZoomButtonsState(pageNumber) {
    this.leftPage.classList.toggle("clickable", pageNumber > 0);
    this.rightPage.classList.toggle(
      "clickable",
      pageNumber < this.canvases!.length
    );
  }

  async addPagePreviews() {
    this.canvases = Array.from(Array(this.issue.pageCount).keys()).map(
      (pageNumber) => {
        return new Canvas(this.imageUrl(pageNumber));
      }
    );
    await Promise.all(
      this.canvases.map((canvas) => {
        canvas.markAsDirty(this.sourceImageDimensions!, this.imageDimensions!);
        return canvas.updateImage(this.sourceImageDimensions![0]);
      })
    );
  }

  fixPageNumber(pageNumber: number | null): number {
    console.assert(this.canvases != null);
    if (pageNumber == null) {
      return 0;
    } else {
      return Math.max(0, Math.min(pageNumber, this.canvases!.length));
    }
  }

  async requestPage(pageNumber: number) {
    console.assert(pageNumber >= 0 && pageNumber <= this.canvases!.length);
    this.targetPageNumber = pageNumber;

    if (!this.loadInProgress) {
      this.loadInProgress = true;
      this.updateZoomButtonsState(NaN);
      await this.moveToTargetPage();
      this.updateZoomButtonsState(this.activePageNumber);
      this.loadInProgress = false;
    }
  }

  get pageCount() {
    console.assert(this.canvases != null);
    return this.canvases!.length;
  }

  async load() {
    const ALLOW_CLICK_DURATION = 250;
    const BOUND_RATIO = 0.1;

    this.triggerResize(0);

    let bounds = {
      bottom: this.imageDimensions![1] * (1 - BOUND_RATIO),
      left: this.imageDimensions![0] * 2 * BOUND_RATIO,
      right: this.imageDimensions![0] * 2 * (1 - BOUND_RATIO),
      top: this.imageDimensions![1] * BOUND_RATIO,
    };
    this.panzoom = panzoom(this.element, {
      bounds,
      minZoom: 1.0,
      maxZoom: 3.0,
      filterKey: () => {
        return true;
      },
      beforeWheel: () => {
        return !this.wheelZoomEnabled;
      },
    });
    this.panzoom.on("transform", () => {
      this.triggerResize(RESIZE_DELAY);
    });
    let panStart: Date | null = null;
    this.panzoom.on("panstart", () => {
      if (this.panningTimeout != null) {
        clearTimeout(this.panningTimeout);
        this.panningTimeout = null;
      }
      this.updateZoomButtonsState(NaN);
      this.panning = true;
      panStart = new Date();
    });
    this.panzoom.on("panend", () => {
      let panEnd = new Date();
      let elapsed = panEnd.valueOf() - panStart!.valueOf();
      let callback = () => {
        this.panning = false;
        this.updateZoomButtonsState(this.activePageNumber);
      };

      if (elapsed > ALLOW_CLICK_DURATION) {
        this.panningTimeout = setTimeout(callback, RESIZE_DELAY);
      } else {
        callback();
      }
    });

    this.addPagePreviews();
    window.visualViewport!.addEventListener("resize", () =>
      this.triggerResize(RESIZE_DELAY)
    );
    this.leftPage.style.display = "grid";
    this.shiftCanvases(0);
  }
}
Object.assign(Newspaper.prototype, Backbone.Events);
