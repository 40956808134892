export function delay<T>(timeout: number, value?: T) {
  return new Promise(function (resolve) {
    setTimeout(resolve.bind(null, value), timeout);
  });
}

export function isMobile(): boolean {
  return window.innerWidth <= 600 || window.innerHeight <= 400;
}

export function oneoff(node, type, callback) {
  function listener(e) {
    node.removeEventListener(type, listener);
    return callback(e);
  }
  node.addEventListener(type, listener);
  return function () {
    node.removeEventListener(type, listener);
  };
}

export function buildHash(parsed) {
  if (parsed.page == null) {
    return "";
  } else {
    return "" + parsed.page;
  }
}

export function parseHash() {
  var parsed = { page: null as number | null };
  var hash = window.location.hash.substr(1);
  var hashMatch = hash.match(/^(\d+)$/);
  if (hashMatch != null) {
    parsed.page = +hashMatch[1];
  }
  return parsed;
}
