import * as _ from "lodash";

export class Issue {
  year: number;
  issue: number;
  date: [number, number, number] | null;

  width: number;
  height: number;

  pageCount: number;

  constructor(
    year: number,
    issue: number,
    date: [number, number, number] | null,
    width: number,
    height: number,
    pageCount: number
  ) {
    this.year = year;
    this.issue = issue;
    this.date = date;
    this.width = width;
    this.height = height;
    this.pageCount = pageCount;
  }
}

export type Manifest = Map<number, Map<number, Issue>>;

export function processManifest(manifest): Manifest {
  var processedManifest = new Map();
  for (let year of manifest["years"]) {
    var issues = new Map(
      _.sortBy(year["issues"], "number").map((issue) => {
        return [
          issue["number"],
          new Issue(
            year["year"],
            issue["number"],
            issue["date"],
            issue["width"],
            issue["height"],
            issue["page_count"]
          ),
        ];
      })
    );
    processedManifest.set(year["year"], issues);
  }
  return processedManifest;
}
